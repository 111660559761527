import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';

(() => {

  function defaultWidthDelegate() {
    return window.innerWidth;
  }

  function defaultHeightDelegate() {
    return window.innerHeight;
  }

  function createAnimation(id, app, widthDelegate = defaultWidthDelegate, heightDelegate = defaultHeightDelegate) {
    const width = widthDelegate();
    const height = heightDelegate();
    const aspectRatio = width / height;

    app.uniforms = {
      ...app.getUniforms(),
      /* eslint-disable camelcase */
      u_time: { value: 0 },
      u_mouse: { value: { x: 0, y: 0 }},
      u_resolution: { value: { x: width, y: height }},
      /* eslint-enable camelcase */
    };

    // Create scene
    app.scene = new THREE.Scene();

    // Create renderer
    app.renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true});
    app.renderer.setPixelRatio(window.devicePixelRatio);
    app.renderer.setSize(width, height);

    // Bind renderer to targeted DOM element
    const container = document.getElementById(id);
    container.appendChild(app.renderer.domElement);

    // Create camera
    app.camera = new THREE.PerspectiveCamera(60, aspectRatio, 1, 100);
    app.camera.updateProjectionMatrix();

    // Create controls
    app.controls = new OrbitControls(app.camera, app.renderer.domElement);
    app.controls.enabled = false;
    app.controls.autoRotate = true;
    app.controls.autoRotateSpeed = 0.75;

    // Bind the app instance to the container element
    Object.assign(app, { ...app, container });

    // Add resize event listener
    window.addEventListener('resize', () => {
      const width = widthDelegate();
      const height = heightDelegate();
      app.camera.aspect = width / height;
      app.camera.updateProjectionMatrix();
      app.renderer.setSize(width, height);
      app.uniforms.u_resolution.value.x = width;
      app.uniforms.u_resolution.value.y = height;
    });

    // Create the animation clock
    const clock = new THREE.Clock();
    app.animate = () => {
      requestAnimationFrame(app.animate);

      const delta = clock.getDelta();
      const elapsed = clock.getElapsedTime();

      app.uniforms.u_time.value = elapsed;

      app.updateScene(delta, elapsed);

      app.renderer.render(app.scene, app.camera);
    };

    return app;
  }

  window.armor = window.armor || {};
  window.armor.animations = {
    THREE,
    createAnimation,
    defaultWidthDelegate,
    defaultHeightDelegate,
  };

})();

// export const createCamera = (
//     fov = 45,
//     near = 0.1,
//     far = 100,
//     camPos = { x: 0, y: 0, z: 5 },
//     camLookAt = { x: 0, y: 0, z: 0 },
//     aspect = window.innerWidth / window.innerHeight,
// ) => {
//     return camera
// }

// window.armor = window.armor || {};
// window.armor.animations = {
//   createCamera,
//   createRenderer,
// };
